/**
 * ================================首页相关================================
 */
// 首页
export const homepage = '/'
// new homes 首页
export const newhomespage = '/new-homes'
// new homes Apartments
export const newhomespageApartments = '/new-homes/new-apartments-and-terrace-housing'
// new homes house and land
export const newhomespageHouseLand = '/new-homes/house-and-land-packages'
// new homes builders
export const newhomespageBuilders = '/new-homes/builders'
// commercial 首页
export const commercialHomepage = '/commercial'
// rural 首页
export const ruralHomepage = '/rural'
// businesses 首页
export const businessesHomepage = '/businesses-for-sale'
// rent 首页
export const rentHomepage = '/search/houses-for-rent/region_all-new-zealand-1_page_1'
// sold 首页
export const soldHomepage = '/search/sold/region_all-new-zealand-1_page_1'
// suburb 首页
export const suburbHomepage = '/suburb'
// estimate 首页
export const estimateHomepage = '/estimate/map/region_auckland-35'
// news 首页
export const newsHomepage = '/news'
// agent 首页
export const agentHomepage = '/find-an-agent'
// first-home-buyers
export const firsthomebuyers = '/advice/first-home-buyers'
// property-report
export const propertyReport = '/insights/property-report'
// house-price-report
export const housePriceReport = '/insights/house-price-report'
// house-price-report
export const sellersGuide = '/advice/sellers-guide'
// New builds guide
export const newBuildsGuide = '/advice/new-builds'
// Homes We Love
export const homesWeLove = '/h/homes-we-love'
// Home Insurance Checklists
export const homeInsuranceChecklists = '/advice/insurance'
// Home Styling
export const homeStyling = '/advice/home-styling'


/**
 * ================================个人中心相关================================
 */
// 个人中心
export const profile = '/profile'
// 个人中心 - 保存搜索
export const profileSavedSearch = '/profile/saved-search'
// 个人中心 - 保存搜索详情(通常从消息进入)
export const profileSavedSearchDetail = '/profile/saved-search/list/{id}'
// 个人中心 - 保存房源
export const profileLists = '/profile/lists'
// 个人中心 - 保存房源列表
export const profileSavedSearchList = '/profile/lists/{id}'
// 个人中心 - 通知
export const profileNotification = '/profile/notification'
// 个人中心 - 查看历史记录
export const profileHistory = '/profile/history'
// 个人中心 - 邮件咨询
export const profileEnquiry = '/profile/enquiry'
// 个人中心 - 聊天咨询
export const profileChatEnquiry = '/profile/chat-enquiry'
// 个人中心 - 我的规划
export const profileJourney = '/profile/journey'
// 个人中心 - 邮件订阅
export const profileCommunications = '/profile/communications'
// 个人中心 - 修改密码
export const profilePassword = '/profile/password'
// 个人中心 - 推荐房源
export const profileRecommended = '/profile/recommended'
// 个人中心 - 认领房源
export const profileTrackedProperties = '/profile/tracked/properties'
// 个人中心 - 认领城区
export const profileTrackedSuburb = '/profile/tracked/suburbs'
// 个人中心 - 删除账号
export const profileDeleteAccount = '/delete-account'


/**
 * ================================news 相关================================
 */
// 新闻hubpage
export const newsHubpage = '/news/{hubSlug}'
export const adviceHubpage = '/advice/{hubSlug}'
export const hHubpage = '/h/{hubSlug}'
export const insightsHubpage = '/insights/{hubSlug}'
// 新闻详情
export const newsDetail = '/{prefix}/{slug}'
// 新闻作者相关
export const newsByAuthor = '/news/author/{name}'
// 新闻tag相关
export const newsTagList = '/news/tag/{slug}'
// 新闻搜索
export const newsSearchList = '/news/search/{keywords}'
// 新闻Carousel列表
export const newsCarouselList = '/news/carousel/{slug}'


/**
 * ================================中介相关================================
 */
// 中介列表不同类型前缀
export const agentSearchType = {
  residential: 'residential',
  rural: 'rural',
  commercial: 'commercial',
  business: 'business',
  managment: 'property-managment',
  realEstate: 'residential'
}
// 中介列表type=(residential|rural|commercial|business|property-managment|residential)
export const agentSearchList = '/agent/search/{type}/{slug}'
// 中介详情
export const agentDetail = '/agent/{slug}'
// 评论中介页面
export const agentReview = '/agent-review/{slug}'



/**
 * ================================中介公司相关================================
 */
// 中介公司详情页面
export const agencyDetail = '/agency/{slug}'
// 中介公司列表type={residential|property-managment}
export const officeSearchList = '/agency/search/{type}/{slug}'



/**
 * ================================房源相关================================
 */
// 房源列表不同类型前缀
export const houseSearchType = {
  residential: 'houses-for-sale',
  rent: 'houses-for-rent',
  sold: 'sold',
  rural: 'rural',
  commercialForSale: 'commercial-property-for-sale',
  commercialForLease: 'commercial-property-for-lease',
  business: 'businesses-for-sale',
  newHomes: 'new-homes',
}
// 房源列表type=(houses-for-sale|houses-for-rent|sold|rural|commercial-property-for-sale|commercial-property-for-lease|businesses-for-sale|new-homes)
export const houseSearchList = '/search/{type}/{slug}'
// 房源详情
export const houseDetail = '/property/{slug}'
// commercial详情
export const commercialDetail = '/commercial/{slug}'
// businesses详情
export const businessesDetail = '/businesses/{slug}'
// builder
export const builderSearchList = '/builder/{slug}'
// builder detail
export const builderDetail = '/builder/detail/{slug}'

/**
 * ================================估价相关================================
 */
// 估价地图
export const estimateMap = '/estimate/map/{slug}'

/**
 * ================================城区相关================================
 */
// suburb detail
export const suburbDetail = '/suburb/{slug}'

/**
 * ================================profile builder================================
 */
// profile builder
export const profileBuilder = '/p/{slug}'

/**
 * ================================其它页面================================
 */
// about us
export const aboutUs = '/real-estate'
// privacy policy
export const privacyPolicy = '/privacy-policy'
// disclaimer
export const disclaimer = '/disclaimer'
// user terms
export const userTerms = '/user-terms'
// faq
export const faq = '/faq'
// property valuations
export const propertyValuations = '/property-valuations'
// sales type
export const salesType = '/sales-type'
// nzme house
export const nzmeHouse = '/nzme-house/{slug}'
